// Resources.js
import React, { useState } from 'react';
import { Container, Row, ListGroup, Col, Tab, Nav } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
import { CourseCards } from './CourseCards';
import course1 from '../assets/img/1.png';
import course2 from '../assets/img/2.png';
import course3 from '../assets/img/3.png';
import course4 from '../assets/img/4.png';
import course5 from '../assets/img/5.png';
import course6 from '../assets/img/6.png';
import course7 from '../assets/img/7.png';
import course8 from '../assets/img/8.jpg';
import course9 from '../assets/img/9.png';
import journal from '../assets/img/journal.png';
import testimonial1 from '../assets/img/testimonial1.png';
import testimonial2 from '../assets/img/testimonial2.png';
import testimonial3 from '../assets/img/testimonial3.png';
import testimonial4 from '../assets/img/testimonial4.png';
import colorSharp2 from '../assets/img/color-sharp2.png';

export const Resources = () => {
  const [hasAnimated, setHasAnimated] = useState(false);

  const miniCourse = [
    { imgUrl: course1, url: 'https://youtu.be/WboLVqIEbdg?si=OXioB-Ej7jSYVpaH' },
    { imgUrl: course2, url: 'https://youtu.be/5XBUhbCGxi0?si=VKGCXtoRLI0Fkku4' },
    { imgUrl: course3, url: 'https://youtu.be/RJ19Mfm4gxU?si=3CLA0nAAbyb8QAQL' },
    { imgUrl: course4, url: 'https://youtu.be/2z0iK2mw1tU?si=Hi7M7bCjz3NLQpGH' },
    { imgUrl: course5, url: 'https://youtu.be/BxPCcXy8sho?si=ouaYZ0p-f26cjuBk' },
    { imgUrl: course6, url: 'https://youtu.be/rbTwHpLqakc?si=kBShmPLl77YCOpD5' },
    { imgUrl: course7, url: 'https://youtu.be/5XBUhbCGxi0?si=y_0ln-g1kXVQWva0' },
    { imgUrl: course8, url: 'https://youtu.be/cz2s9QGuq1k?si=nHW0Q8AC7WKxkAnx' },
    { imgUrl: course9, url: 'https://youtu.be/oUlgDRhczYQ?si=mB5cwoq2JeqT5hV6' },
  ];

  return (
    <section className="resources" id="resources">
      <Container>
        <Row>
          <Col xs={12}>
            <TrackVisibility
              onChange={(isVisible) => {
                if (isVisible && !hasAnimated) {
                  setHasAnimated(true);
                }
              }}
            >
              {({ isVisible }) => (
                <div className={`animate__animated ${isVisible ? 'animate__fadeIn' : ''}`}>
                  <h2>Resources</h2>
                  <p></p>
                  <Tab.Container id="resources-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">1-on-1 Sessions</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Free Mini Course</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Notion Journal</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={`animate__animated ${hasAnimated ? 'animate__slideInUp' : ''}`}
                    >

                      {/* 1-on-1 sessions */}
                      <Tab.Pane eventKey="first">
                        <div className='sessions' id='testimonials'>
                        <h1>
                          <a href="https://calendly.com/sirpickle/1h?month=2024-07">
                            Book a 1-on-1 Session Now!
                          </a>
                        </h1>
                        <p>Here's what the members say:</p>
                        <ListGroup>
                          <ListGroup.Item
                            style={{
                              width: '100%', // Make the ListGroup.Item fill the available width
                              height: 'auto', // Adjust height automatically to maintain aspect ratio
                              backgroundColor: 'transparent',
                              border: 'none',
                            }}
                          >
                            <img
                              src={testimonial1}
                              alt="Testimonial"
                              style={{
                                width: '100%', // Make the image fill the ListGroup.Item width
                                height: 'auto', // Maintain the aspect ratio of the image
                                display: 'block', // Remove any unwanted bottom margin/space
                              }}
                            />
                            <img
                              src={testimonial2}
                              alt="Testimonial"
                              style={{
                                width: '100%', // Make the image fill the ListGroup.Item width
                                height: 'auto', // Maintain the aspect ratio of the image
                                display: 'block', // Remove any unwanted bottom margin/space
                              }}
                            />
                            <img
                              src={testimonial3}
                              alt="Testimonial"
                              style={{
                                width: '100%', // Make the image fill the ListGroup.Item width
                                height: 'auto', // Maintain the aspect ratio of the image
                                display: 'block', // Remove any unwanted bottom margin/space
                              }}
                            />
                            <img
                              src={testimonial4}
                              alt="Testimonial"
                              style={{
                                width: '100%', // Make the image fill the ListGroup.Item width
                                height: 'auto', // Maintain the aspect ratio of the image
                                display: 'block', // Remove any unwanted bottom margin/space
                              }}
                            />
                          </ListGroup.Item>
                        </ListGroup>
                        </div>
                        <br />
                        <div className="faqs">
                          <h1 style={{ textAlign: 'center' }}>FAQs</h1>
                          <h3>1. Who is this for?</h3>
                          <p>
                            This is for those who are <b><u>dedicated traders</u></b> who have an understanding of the foundational ICT concepts but are not yet consistent.
                          </p>
                          <h3>2. What to expect?</h3>
                          <p>
                            I will <b><u>expedite</u></b> your learning curve by reverse engineering your issues, going over your trades, giving an in-depth look at my personal model, answering any questions, and overall doing my absolute best to make sure you are on the right track.
                          </p>
                          <h3>3. Where will this session be held?</h3>
                          <p>
                            In a private Discord call.
                          </p>
                        </div>
                        <br />
                      </Tab.Pane>
                      
                      {/* Free Mini Course */}
                      <Tab.Pane eventKey="second">
                        <div className='miniCourse'>
                        <h1>Learn how to master the markets!</h1>
                        <p>Below are a collection of lessons about my model.
                        It is based on Fair Value Gaps and Swing Highs and Swing Lows.
                        Pure simplicity.</p>
                        <Row>
                          {miniCourse.map((course, index) => (
                            <Col key={index} xs={12} sm={6} md={4}>
                              <CourseCards {...course} />
                            </Col>
                          ))}
                        </Row>
                        <h1>More to come!</h1>
                        </div>
                        <br />
                      </Tab.Pane>

                      {/* Notion Journal */}
                      <Tab.Pane eventKey="third">
                        <div className='journal'>
                        <h1>
                          <a href="https://sirpickle.notion.site/b2f71d00daf94b3a8b23c86aa840373a?v=a96236c8eede4c22a10d672d41fc2cf4">
                            Get this FREE Notion Journal!
                          </a>
                        </h1>
                        <img
                          src={journal}
                          alt="Notion Journal"
                          style={{
                            width: '100%',
                            maxWidth: '1500px',
                            margin: '0 auto',
                            display: 'block',
                          }}
                        />
                        </div>
                        <br></br>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background Design" />
    </section>
  );
};
